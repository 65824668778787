@use '@angular/material' as mat;
.loading {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: #ffffff;
  z-index: -1;
  color: mat.get-color-from-palette($primary, 400);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin-bottom: 40px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: mat.get-color-from-palette($primary, 400) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
/* .loading screen is visible when app is not bootstrapped yet, my-app is empty */
twaice-fe-root:empty + .loading {
  opacity: 1;
  z-index: 100;

  .lds-ring {
    animation-play-state: paused;
  }
}
