// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.

@import 'styles/tailwind.src';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$twaice-ui-primary: mat.define-palette(mat.$indigo-palette);
$twaice-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$twaice-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$twaice-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $twaice-ui-primary,
      accent: $twaice-ui-accent,
      warn: $twaice-ui-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($twaice-ui-theme);

// external libraries
@import '~daterangepicker/daterangepicker.css';

// local styles
@import 'twaice-theme';
@import 'loader';

@include twaice-theme();

@import 'styles/components/headers';

html,
body {
  height: 100%;
}

body {
  margin: 0;

  &.disabled-scrolling {
    position: fixed;
    overflow-y: scroll;
    width: 100%;
  }
}

// TODO get rid of this aggressive link styling
a:not(.mat-flat-button):not(.mat-stroked-button):not(.cc-allow) {
  text-decoration: none;
  color: $colorLink;

  &:hover {
    text-decoration: underline;
  }
}

// temporary overwrite for link styling above
.overwrite-link-styling {
  color: inherit !important;
  text-decoration: none !important;
}

.clickable:hover {
  cursor: pointer;
}

img:focus {
  outline: 0;
}

img[routerLink]:hover {
  cursor: pointer;
}

.spacer {
  flex: 1 1 auto;
}

.bg-danger {
  color: mat.get-contrast-color-from-palette($primary, 400) !important;
  background-color: $bgColorDanger;
  border-radius: $border-radius !important;
  border-color: mat.get-contrast-color-from-palette($primary, 400);
}

.bg-success {
  color: mat.get-contrast-color-from-palette($primary, 400) !important;
  background-color: $bgColorSuccess;
  border-radius: $border-radius !important;
  border-color: mat.get-contrast-color-from-palette($primary, 400);
}

table {
  width: 100%;
}

.bg-color-1 {
  background-color: $blue-gray-300;
}

.text-danger {
  color: $colorDanger;
}

.text-gray,
.text-grey {
  color: mat.get-color-from-palette($twaice-gray, 500);
}

button,
input,
mat-chip,
mat-select,
.mat-menu-item {
  &[disabled],
  &[aria-disabled='true'] {
    &,
    & * {
      cursor: not-allowed !important;
      pointer-events: initial !important;
    }
  }
}

.mat-form-field-disabled,
.mat-checkbox-disabled {
  cursor: not-allowed !important;
  pointer-events: initial !important;
}

// TODO replace usage with Tailwind utility
.font-sm {
  font-size: $font-size-sm;
}

.plot-container {
  svg {
    display: inline-block !important;
  }
}

// overwrite cookieconsent styles
.cc-window {
  font-size: $font-size-sm;
  border: 1px solid $blue-gray-300;
}

.tw-container {
  padding: $spacer $spacer;
}

.ant-tooltip-inner {
  color: rgba(0, 0, 0, 0.85);
  background-color: white;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: white;
}
