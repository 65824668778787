h1 {
  font-size: theme('fontSize.3xl') !important;
  font-weight: theme('fontWeight.semibold') !important;
  line-height: theme('lineHeight.2xl') !important;
}

h2 {
  font-size: theme('fontSize.2xl') !important;
  font-weight: theme('fontWeight.semibold') !important;
  line-height: theme('lineHeight.xl') !important;
}

h3 {
  font-size: theme('fontSize.xl') !important;
  font-weight: theme('fontWeight.semibold') !important;
  line-height: theme('lineHeight.xl') !important;
  margin: 0 !important;

  /* temp so we do not break existent, needs to be replaced with tailwind later */
  color: mat.get-color-from-palette($primary, 600) !important;
}

h4 {
  font-size: theme('fontSize.lg') !important;
  font-weight: theme('fontWeight.semibold') !important;
  line-height: theme('lineHeight.lg') !important;
}

h5 {
  font-size: theme('fontSize.base') !important;
  font-weight: theme('fontWeight.semibold') !important;
  line-height: theme('lineHeight.lg') !important;
}

h6 {
  font-size: theme('fontSize.sm') !important;
  font-weight: theme('fontWeight.semibold') !important;
  line-height: theme('lineHeight.base') !important;
}
